import { PaymentConfig } from '@frontend/client/contexts/useYodlStore';
import {
  PREFERENCES_TEXT_KEY,
  PREFERENCES_TEXT_KEY_LEGACY,
  YODL_ENS_DOMAIN,
} from '@frontend/common/constants';
import {
  useAddSubname,
  usePrimaryName,
  useRecords,
  useUpdateSubname,
} from '@justaname.id/react';
import { useMutation } from '@tanstack/react-query';
import assert from 'assert';
import { useMemo } from 'react';
import { GetEnsTextReturnType, isAddress } from 'viem';
import {
  arbitrum,
  base,
  gnosis,
  mainnet,
  optimism,
  polygon,
} from 'viem/chains';

// Type definitions
interface Props {
  addressOrEns?: string;
}

interface Preferences {
  chains: number[];
  tokens: string[];
  [key: string]: any; // Allow for additional properties
}

interface SignPreferencesProps {
  username?: string;
  isRegistered?: boolean;
}

// Chain mappings
const CHAIN_MAPPINGS = {
  nameToId: {
    eth: mainnet.id,
    oeth: optimism.id,
    arb1: arbitrum.id,
    pol: polygon.id,
    base: base.id,
    gno: gnosis.id,
  },
  // Create reverse mapping from chain ID to name
  idToName: {} as Record<number, string>,
};

// Initialize the idToName mapping
CHAIN_MAPPINGS.idToName = Object.entries(CHAIN_MAPPINGS.nameToId).reduce(
  (acc, [name, id]) => ({
    ...acc,
    [id]: name,
  }),
  {},
);

// Utility functions
const stringUtils = {
  arrayToCommaString: (array: string[] | number[]) => array.join(','),
  commaStringToArray: (commaString: string) =>
    commaString.split(',').map((item) => item.trim()),
};

/**
 * Parses preferences from ENS text records
 */
const parsePreferences = (preferences: GetEnsTextReturnType | undefined) => {
  if (!preferences) return undefined;

  try {
    const {
      // Legacy fields
      chainIds,
      tokenSymbols,
      // New fields
      chains,
      tokens,
      // Other fields
      redirectUrl,
      currency,
      amount,
      webhooks,
      og,
      ...rest
    } = JSON.parse(preferences);

    // Validate amount if present
    if (amount !== undefined && (typeof amount !== 'number' || amount < 0)) {
      throw new Error('Amount must be a positive number');
    }

    // Use new fields if available, otherwise fall back to legacy fields
    let chainConfig = chains || chainIds;
    let tokenConfig = tokens || tokenSymbols;

    // Normalize chain config to array format
    if (typeof chainConfig === 'string') {
      chainConfig = stringUtils.commaStringToArray(chainConfig);
    }

    // Normalize token config to array format
    if (typeof tokenConfig === 'string') {
      tokenConfig = stringUtils.commaStringToArray(tokenConfig);
    }

    // Process chain configuration
    if (Array.isArray(chainConfig)) {
      chainConfig = chainConfig
        .map((chain) => {
          if (typeof chain === 'string') {
            // Direct mapping from name to ID
            if (CHAIN_MAPPINGS.nameToId[chain]) {
              return CHAIN_MAPPINGS.nameToId[chain];
            }

            // Handle case where chain is a name in the reverse mapping
            if (Object.values(CHAIN_MAPPINGS.idToName).includes(chain)) {
              const matchingId = Object.keys(CHAIN_MAPPINGS.idToName).find(
                (id) => CHAIN_MAPPINGS.idToName[parseInt(id)] === chain,
              );
              return matchingId ? parseInt(matchingId, 10) : undefined;
            }

            // Try parsing as a number
            const parsedChain = parseInt(chain, 10);
            return isNaN(parsedChain) ? undefined : parsedChain;
          }
          return chain;
        })
        .filter((id) => id !== 0 && id !== undefined);
    }

    // Ensure token config is an array of strings
    if (Array.isArray(tokenConfig)) {
      tokenConfig = tokenConfig.map((token) => String(token));
    }

    // Construct the final config object
    return {
      chainIds: chainConfig,
      tokenSymbols: tokenConfig,
      redirectUrl,
      currency,
      amount,
      webhooks,
      og,
      ...rest,
    } as PaymentConfig;
  } catch (error) {
    console.error('Failed to parse preferences:', error);
    return undefined;
  }
};

/**
 * Hook to retrieve signed preferences for an address or ENS name
 */
const useSignedPreferences = ({ addressOrEns }: Props) => {
  const isAddressValue = isAddress(addressOrEns || '');

  // If address, get the primary name
  const { primaryName, isPrimaryNameLoading, isPrimaryNameFetching } =
    usePrimaryName({
      address: isAddressValue ? addressOrEns : undefined,
      enabled: !!isAddressValue,
    });

  const ensName = isAddressValue ? primaryName : addressOrEns;

  const { records, isRecordsLoading, isRecordsFetching, isRecordsPending } =
    useRecords({
      ens: ensName,
      chainId: mainnet.id,
    });

  // Parse preferences, trying current format first, then legacy
  const preferences = useMemo(() => {
    const result = records?.records.texts.find(
      (record) => record.key.trim() === PREFERENCES_TEXT_KEY,
    );
    const resultLegacy = records?.records.texts.find(
      (record) => record.key.trim() === PREFERENCES_TEXT_KEY_LEGACY,
    );

    return (
      parsePreferences(result?.value) || parsePreferences(resultLegacy?.value)
    );
  }, [records]);

  return {
    preferences,
    isLoading: isPrimaryNameLoading || isRecordsLoading,
    isFetching: isRecordsFetching || isPrimaryNameFetching,
    isPending: isRecordsPending,
  };
};

/**
 * Hook to sign and save preferences
 */
const useSignPreferences = ({
  username,
  isRegistered,
}: SignPreferencesProps) => {
  const { addSubname } = useAddSubname();
  const { updateSubname } = useUpdateSubname();

  return useMutation({
    mutationFn: async ({ tokens, chains, ...rest }: Preferences) => {
      assert(username, 'ENS is required');

      // Convert chain IDs to chain names
      const processedChains = chains.map(
        (chain) => CHAIN_MAPPINGS.idToName[chain],
      );

      // Prepare preferences object
      const preferences: Record<string, any> = {
        tokens: stringUtils.arrayToCommaString(tokens),
        chains:
          processedChains.length > 0
            ? stringUtils.arrayToCommaString(processedChains)
            : undefined,
        ...rest,
      };

      const text = {
        [PREFERENCES_TEXT_KEY]: JSON.stringify(preferences),
      };

      // Update or add subname based on registration status
      if (isRegistered) {
        await updateSubname({
          text,
          ens: username,
        });
      } else {
        await addSubname({
          text,
          username,
          ensDomain: YODL_ENS_DOMAIN,
        });
      }

      window?.location.reload();
    },
  });
};

export { useSignedPreferences, useSignPreferences };
