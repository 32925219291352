import type { TokenValue } from '@frontend/types';
import type { TokenInfo } from '@yodlpay/tokenlists';
import { getTokenBySymbol } from '@yodlpay/tokenlists';
import { Address, type Chain } from 'viem';
import * as chains from 'viem/chains';
import { SUPPORTED_CHAINS } from '@frontend/common';
import { isEqualsIgnoreCase } from './string';

export const NATIVE_TOKEN_ADDRESS =
  '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE' as Address;

export const filterSearchParams = (
  tokens: string | string[] | undefined,
  chains: string | string[] | undefined,
) => {
  tokens = tokens ? tokens : [];
  tokens = Array.isArray(tokens) ? tokens : [tokens];
  chains = chains ? chains : [];
  chains = Array.isArray(chains) ? chains : [chains];

  const _chains = chains.map((c) => parseInt(c));
  const supportedChainIds = SUPPORTED_CHAINS.map((c) => c.id as number);
  const validChains =
    _chains.length === 0
      ? supportedChainIds
      : _chains.filter((c) => supportedChainIds.includes(c));

  const filteredTokens = new Set<string>();
  const filteredChains = new Set<number>();
  for (const token of tokens) {
    for (const chain of validChains) {
      if (getTokenBySymbol(token, chain)) {
        filteredChains.add(chain);
        filteredTokens.add(token);
      }
    }
  }

  return [
    Array.from(filteredTokens),
    Array.from(filteredChains)
      .sort((a, b) => a - b)
      .map((c) => c.toString()),
  ];
};

// * true if both addresses match (lowercase)
// * false if both addresses are undefined (something weird happening)
export function isAddressMatching(
  addr1: string | undefined | null,
  addr2: string | undefined | null,
) {
  if (!addr1 && !addr2) {
    // console.log("isAddressMatching compares to undefined addresses. returning false")
    return false;
  } else {
    return isEqualsIgnoreCase(addr1, addr2);
  }
}

export const getViemChain = (id: number): Chain | undefined => {
  return Object.values(chains).find((x) => x.id === id);
};

export const invoiceAmountToTokenValue = (
  invoiceAmount: number | undefined,
  exchangeRate: bigint,
  tokenOutInfo: TokenInfo,
) => {
  if (!invoiceAmount) return { value: 0n, decimals: tokenOutInfo.decimals };

  const { decimals } = tokenOutInfo;
  const minorDecimals = 2; // invoice amount is in minor units (2 decimals)

  const invoiceAmountAdjusted = BigInt(invoiceAmount) * BigInt(10 ** decimals);

  // Exchange rate is in 18 decimals, adjust for target token's decimals
  const exchangeRateMultiplier = BigInt(10 ** (18 - decimals));
  // Also adjust for the token decimals and minor units
  const decimalsAndMinorMultiplier = BigInt(10 ** (decimals + minorDecimals));

  const value =
    (invoiceAmountAdjusted * exchangeRate) /
    exchangeRateMultiplier /
    decimalsAndMinorMultiplier;

  return {
    value,
    decimals,
  } as TokenValue;
};

export const tokenValueWithExchangeRate = (
  tokenValue: TokenValue,
  exchangeRate: bigint,
) => {
  const { value, decimals } = tokenValue;

  const amountAdjusted = BigInt(value);

  // We default to 18 decimals in exchangeRates.
  // TODO: standdardize to a constant EXCHANGE_RATE_DECIMALS
  const exchangeRateMultiplier = 10n ** 18n;

  const converted = (amountAdjusted * exchangeRate) / exchangeRateMultiplier;

  return {
    value: converted,
    decimals,
  } as TokenValue;
};
