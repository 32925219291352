import { safeWindow } from '@frontend/utils/safeWindow';
import { useEffect, useState } from 'react';

export const MOBILE_QUERY = '(max-width: 768px)';

export const useMediaQuery = (query: string): boolean => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const mediaQuery = safeWindow?.matchMedia(query);
    if (!mediaQuery) return;

    setMatches(mediaQuery.matches);

    const handler = (event: MediaQueryListEvent) => setMatches(event.matches);
    mediaQuery.addEventListener('change', handler);

    return () => mediaQuery.removeEventListener('change', handler);
  }, [query]);

  return matches;
};
