import { Container, ContainerProps, Grid } from '@radix-ui/themes';
import React, { PropsWithChildren } from 'react';

interface Props {
  className?: string;
  containerClassName?: string;
  size?: ContainerProps['size'];
  px?: ContainerProps['px'];
  mt?: ContainerProps['mt'];
}

const AppContainer: React.FC<PropsWithChildren<Props>> = ({
  children,
  className,
  containerClassName,
  size = '1',
  px = { initial: '4', sm: '0' },
  mt = '6',
}) => {
  return (
    <Container size={size} px={px} className={containerClassName}>
      <Grid mt={mt} className={className} gapY="3">
        {children}
      </Grid>
    </Container>
  );
};

export default AppContainer;
