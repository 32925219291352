import { cn } from '@frontend/utils/tailwindUtil';
import { Flex, IconButton, Text } from '@radix-ui/themes';
import { FC, ReactNode } from 'react';
import { IoClose } from 'react-icons/io5';

interface DialogHeaderProps {
  className?: string;
  title?: string | ReactNode;
  showClose?: boolean;
  isCustomBg?: boolean;
  onClose: () => void;
  fullScreenPage?: boolean;
  hasPadding?: boolean;
}

const DIALOG_CONSTANTS = {
  HEADER_HEIGHT: 100,
  LOGO_WIDTH: 8 * 5,
} as const;

export const DialogHeader: FC<DialogHeaderProps> = ({
  className,
  title,
  showClose,
  isCustomBg,
  onClose,
  fullScreenPage,
  hasPadding,
}) => {
  const renderTitle = () => {
    if (typeof title === 'string') {
      return (
        <Text size="5" weight="bold">
          {title}
        </Text>
      );
    }
    return title;
  };

  return (
    <Flex
      justify="between"
      align="center"
      className={cn(
        'pt-4 md:pb-4',
        className,
        fullScreenPage && 'px-4 pb-4',
        !hasPadding && 'px-4',
      )}
    >
      {showClose ? (
        <IconButton
          variant="ghost"
          radius="full"
          m="0"
          onClick={onClose}
          className={cn(
            'w-8 h-8 flex items-center justify-center',
            'transition-all duration-200 ease-in-out',
            'hover:scale-110',
            isCustomBg
              ? 'hover:bg-white/20'
              : 'hover:bg-gray-100 dark:hover:bg-gray-800',
          )}
          aria-label="Close dialog"
        >
          <IoClose
            className={cn(
              'w-5 h-5 transition-colors duration-200',
              isCustomBg
                ? 'text-white/90 hover:text-white'
                : 'text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100',
            )}
          />
        </IconButton>
      ) : (
        <div className="w-8" />
      )}

      {title ? renderTitle() : null}

      <div className="w-5 h-5" />
    </Flex>
  );
};
