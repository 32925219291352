import _ from 'lodash';

export function isEqualsIgnoreCase(
  a: string | undefined | null,
  b: string | undefined | null,
) {
  return (
    (a || '').localeCompare(b || '', 'en-US', { sensitivity: 'base' }) === 0
  );
}

export function includesIgnoreCase(
  haystack: string[],
  needle: string | undefined | null,
) {
  return haystack.some((element) => isEqualsIgnoreCase(element, needle));
}
