// import node_modules
import { isValidNumber } from '@frontend/common';
import parseTokenImages from '@frontend/utils/parseImages';
import type { ChainInfo } from '@yodlpay/tokenlists';
import { getChain } from '@yodlpay/tokenlists';
import { type Chain } from 'viem';
import { getViemChain } from './tokenHelpers';

/**
 ```
 "ETH" => 1
 "eth" => 1
 "1" => 1
 foobar = undefined
 ```
*/
export function resolveChainIdOrShortName(
  chainIdOrShortName: string,
): number | undefined {
  if (isValidNumber(chainIdOrShortName)) {
    return Number(chainIdOrShortName);
  } else {
    return CHAIN_SHORT_NAMES[chainIdOrShortName.toLowerCase()];
  }
}

export const CHAIN_SHORT_NAMES = {
  eth: 1,
  oeth: 10,
  op: 10,
  bsc: 56,
  pol: 137,
  gno: 100,
  arb1: 42161,
  base: 8453,
} as const;

export const chainIdToShortName = (chainId: number): string | undefined => {
  const entries = Object.entries(CHAIN_SHORT_NAMES);
  const found = entries.find(([_, id]) => id === chainId);
  return found ? found[0] : undefined;
};

export const chainById = (
  id: number,
): { viemChain: Chain; chainInfo: ChainInfo } => {
  const viemChain = getViemChain(id);
  const _chainInfo = getChain(id);

  if (!viemChain) throw `viemChain not found for id=${id}`;
  if (!_chainInfo) throw `chainInfo not found for id=${id}`;

  const chainInfo = {
    ..._chainInfo,
    logoUri: parseTokenImages(_chainInfo.logoUri) || '',
  };

  if (!chainInfo.logoUri) {
    throw `chainInfo.logoUri not found for id=${id}`;
  }

  return {
    viemChain,
    chainInfo,
  };
};
